/* stylelint-disable no-descending-specificity */
@import "variables";
@import "bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
  .sidebar-navigation {
    display: none;
  }
}

.sidebar-navigation .component-content {
  h3 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.8;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    padding: 0;
  }

  a {
    color: $text-basic;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.8;

    &:focus,
    &:hover,
    &.active {
      color: var(--theme-color-2);
    }
  }
}
