@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";


.latest-blogs.food {
  padding-top: 3rem;
  position: relative;

  .title {
    color: rgba(255, 255, 255, 87%);
    font-size: 2.375rem;
  }

  .subtitle {
    color: rgba(255, 255, 255, 50%);
    font-weight: 400;
  }

  .link {
    a {
      border-bottom: none;
      color: rgba(255, 255, 255, 87%);
      font-size: 0.75rem;


      &::after {
        @include fa-icon;

        content: $fa-var-long-arrow-right;
        margin-left: 1rem;
      }
    }
  }

  .component-content {
    &::after {
      background:
        linear-gradient(
          180deg,
          var(--theme-color-2) 0%,
          var(--theme-color-1) 65%,
          rgba(255, 255, 255, 100%) 35%,
          rgba(255, 255, 255, 100%)
          100%
        );
      content: "";
      height: 100%;
      left: 0;
      margin-left: calc(50% - 50vw);
      overflow: hidden;
      position: absolute;
      top: 0;
      transform: scaleX(100);
      width: 100px;
      z-index: -1;
    }
  }

  .list-buttons {
    z-index: 0;
  }

  .btn-list-slide {
    background-color: $white;
    border: 2px solid $orange;
    border-radius: 2px;
    color: $orange;
    font-size: 1.75rem;
    height: 40px;
    line-height: 0.8;
    width: 40px;

    &:disabled {
      border-color: $gray-200;
      color: $gray-200;
      opacity: 90%;
    }
  }
}

.latest-blogs {
  .blog-tile-container {
    padding-top: 1rem;
  }

  .blog-tile {
    transition: transform 150ms ease-in-out;
  }

  .blog-tile:hover,
  .blog-tile:focus-within {
    transform: translateY(-1rem);

    .field-teaser-title {
      color: var(--theme-color-2);
    }

    .blog-tile-image {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 12%);
    }
  }

  .blog-tile .blog-tile-link {
    display: block;
    text-decoration: none;
    width: inherit;
  }

  .blog-tile-image {
    border-radius: 10px;
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
  }

  .teaser {
    @extend %text-sans-serif;

    display: flex;
    flex-direction: row;
  }

  .teaser-icon {
    background-color: var(--theme-color-2);
    border-radius: 100%;
    color: $white;
    display: grid;
    flex-shrink: 0;
    height: 2.75rem;
    margin-right: 1rem;
    overflow: hidden;
    place-items: center;
    width: 2.75rem;
  }

  .field-teaser-title {
    font-weight: 700;
  }

  .field-teaser-subtitle {
    color: $gray-900;
    display: block;
    font-size: 0.75rem;
  }

  .author {
    color: $gray-500;
    display: block;
    font-size: 0.75rem;
  }
}

.latest-blogs.default {
  .title {
    color: $gray-900;
  }

  .subtitle {
    color: $gray-900;
    font-weight: 400;
  }

  .link {
    color: $gray-900;

    a {
      border-bottom: none;
      color: rgba(0, 0, 0, 87%);
      font-size: 0.75rem;


      &::after {
        @include fa-icon;

        color: var(--theme-color-2);
        content: $fa-var-long-arrow-right;
        margin-left: 1rem;
      }
    }
  }

  .teaser-list {
    display: grid;
    grid-template-rows: auto;

    @include media-breakpoint-up(lg) {
      min-height: 300px;
    }
  }

  .teaser {
    border-top: 1px solid $gray-200;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &:last-child {
      border-bottom: 1px solid $gray-200;
    }
  }

  .blog {
    display: flex;
  }

  .blog-image {
    border-radius: 10px;
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
  }
}
