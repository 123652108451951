@import "variables";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

$button-height: 50px;
$menu-width: 148px;

.component.LoggedInNavigation {
  .component-content {
    .btn.btn-link.dropdown-toggle {
      background-color: transparent;
      display: flex;
      flex-direction: row;
      height: $button-height;
      justify-content: space-between;
      line-height: 20px;
      z-index: 10;

      &::after {
        display: none !important;
      }

      &:hover,
      &:focus {
        box-shadow: none;
        text-decoration: none;
      }

      &:active,
      &[aria-expanded="true"] {
        box-shadow: $box-shadow-color;
      }

      .fa {
        color: var(--theme-color);
        font-size: 20px;
        line-height: 20px;
        vertical-align: middle;
      }

      #chevron.fa {
        color: $gray-900;
      }

      .text-sans-serif {
        line-height: 20px;
      }

      .userName {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
      }
    }

    ul {
      width: 100%;
    }

    li {
      border-bottom: 1px solid $gray-200;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      max-height: 50px;
      padding: 0;
    }

    /* Assume 'logout' button */
    li:last-child {
      background-color: $gray-100;
      border-bottom: none;

      a::before {
        @include fa-icon;

        color: var(--theme-color-2);
        content: $fa-var-sign-out;
        margin-right: 0.5rem;
      }
    }

    a {
      color: $gray-900;
      display: block;
      height: 100%;
      overflow: hidden;
      padding: 0.75rem 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &:hover {
        color: var(--theme-color-2);
      }

      &.active {
        border-color: transparent;
        color: var(--theme-color-2);
      }
    }
  }
}





@include media-breakpoint-up(md) {
  .component.LoggedInNavigation {
    .component-content {
      text-align: right;
    }

    .btn.btn-link.dropdown-toggle {
      width: 148px;
    }

    .dropdown-menu {
      border: none;
      box-shadow: $box-shadow-color;
      margin-top: 0;
      width: $menu-width;
    }
  }
}
