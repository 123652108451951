/* High specificity required to override existing styles */
@use "sass:math";

@import "variables";
@import "bootstrap/scss/mixins";
@import "utilities/theme-colors";
@import "utilities/typography";

.blog-overview {
  padding-bottom: 2rem;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  width: 100%;

  &::before {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    bottom: 0;
    content: " ";
    left: 0;
    margin-left: calc(50% - 50vw);
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  &.search-results.horizontal .search-result-list {
    column-gap: $grid-gutter-width;
    display: grid !important;
    margin: 0 !important;
    padding-top: 0 !important;
    row-gap: $grid-gutter-width;

    &::after {
      content: unset;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.search-results.horizontal .search-result-list li {
    background: $white;
    flex: unset !important;
    margin: 0 !important;
    min-height: unset !important;
    min-width: unset !important;
    padding: 0 0 1rem !important;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: static !important;

    &:hover {
      border: none !important;
    }

    // image
    > a {
      display: block;
      margin-bottom: 1rem;
    }

    .badge,
    .field-teaser-title,
    .field-teaser-subtitle {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .badge {
      margin-bottom: 0.5rem;
    }

    .field-teaser-title {
      @extend %h2;

      font-size: 1rem;
    }

    .badge.field-title {
      display: inline-block !important;
      padding: 0.5rem 0.675rem !important;
      text-align: left !important;
    }
  }

  @include media-breakpoint-up(xl) {
    &.search-results.horizontal .search-result-list li:first-child {
      background: linear-gradient(67.32deg, var(--theme-color-1) 0%, var(--theme-color-2) 100%);
      grid-column: 1 / span 2;
      padding: 0 !important;
      position: relative !important;

      &::before {
        color: rgba(255, 255, 255, 12%);
        content: "\e80d";
        display: block;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: carellurvink-icon;
        font-size: 210px;
        height: 100%;
        margin-left: -98px;
        margin-top: -130px;
        position: absolute;
        right: 0;
        top: 50%;
        width: 98px;
      }

      &:hover {
        border: none !important;
      }

      > a {
        @include media-breakpoint-up(sm) {
          display: block;
          margin-bottom: 0;
          width: calc(50% - 1rem);
        }
      }

      .badge {
        background-color: $white !important;
        color: var(--theme-color-2) !important;

        @include media-breakpoint-up(sm) {
          left: 50%;
          position: absolute;
          top: 1rem;
        }

        @include media-breakpoint-up(md) {
          top: 3rem;
        }

        @include media-breakpoint-up(lg) {
          top: 103px;
        }
      }

      .field-teaser-title {
        @include media-breakpoint-up(sm) {
          font-size: 1.25rem;
          left: 50%;
          position: absolute;
          top: 3.5rem;
        }

        @include media-breakpoint-up(md) {
          top: 5.5rem;
        }

        @include media-breakpoint-up(lg) {
          top: 150px;
        }

        > a {
          color: $white !important;
        }
      }

      .field-teaser-subtitle {
        left: 50%;
        position: absolute;
        top: 216px;

        > a {
          color: $white !important;
        }
      }
    }
  }

  /* stylelint-disable-next-line */
  &.search-results.horizontal .search-result-list li:first-child .field-teaser-title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}
