$font-extrasmall: 0.625rem;
$font-small: 0.75rem;
$font-normal: 0.875rem;
$font-medium: 1rem;
$font-big: 1.25rem;
$font-extrabig: 1.5rem;

//Navigation
$navigation-font-basic: 18px;
$navigation-font-basic-submenu: 14px;

//Tabs
$tab-font-header: 16px;

//Accordion
$accordion-header: 16px;

//Breadcrumb
$breadcrumb-font: 15px;

// Link List
$link-list-title-font: 15px;
