@import "variables";

@import "../../../components-react/ProductTile/Components/Labels.module.scss";

.labels {
  @extend .Labels; // stylelint-disable-line

  top: 0;
  z-index: 1;
}

.label {
  border-radius: 2px 0 0 2px;
  color: $white;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.425rem 0.625rem;
}
