@use "sass:color";
@import "abstracts/vars";
@import "abstracts/mixins";
@import "partials/mixins";

.component.blog-basic-text {
  .badge {
    background-color: var(--theme-color-2);
  }

  .article-card img {
    max-height: 200px;

    @include media-breakpoint-up(lg) {
      max-height: auto;
    }
  }
}
