@import "variables";
@import "utilities/typography";
@import "partials/mixins";

.product-details-technical {
  tr:first-child th,
  tr:first-child td {
    border-top: none;
  }

  th {
    font-weight: 700;
  }

  th:first-child {
    padding-right: 1.5rem;
    white-space: nowrap;
    width: 1px;
  }
}
