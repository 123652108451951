#icons {
  .fontawesome-icon-list > div {
    color: var(--theme-color) !important;
    font-size: 1.125rem;

    i.fa::before {
      color: rgba(0, 0, 0, 54%) !important;
      font-size: 1em !important;
    }
  }
}
