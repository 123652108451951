@import "variables";
@import "utilities/typography";
@import "partials/mixins";

.product-details-downloads {
  .table {
    border-bottom: 1px solid rgba(0, 0, 0, 21%);

    th {
      border-bottom-width: 1px;
      border-top: none;
      font-weight: bold;
    }

    td.col-download {
      text-align: right;

      a {
        padding-bottom: 0.4rem;
        padding-top: 0.4rem;
      }
    }
  }
}
