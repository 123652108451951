@import "variables";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

$button-height: 50px;
$menu-width: 200px;
@include media-breakpoint-down(sm) {
  .component.LoggedInNavigation {
    position: unset;

    .component-content {
      .btn.btn-link.dropdown-toggle {

      }


      ul {
        width: 100%;
      }

      li {
        border-bottom: 1px solid $gray-200;
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        max-height: 50px;
        padding: 0;
      }

      /* Assume 'logout' button */
      li:last-child {
        border-bottom: none;

        a::before {
          @include fa-icon;

          content: $fa-var-sign-out;
          margin-right: 0.5rem;
        }
      }

      a {
        color: $gray-900;
        display: block;
        height: 100%;
        overflow: hidden;
        padding: 0.75rem 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &:hover {
          // background: linear-gradient(90deg, var(--theme-color-1) 0%, var(--theme-color-2) 100%);
          color: var(--theme-color-2);
        }

        &.active {
          // background: linear-gradient(90deg, var(--theme-color-2) 0%, var(--theme-color-3) 100%);
          border-color: transparent;
          color: var(--theme-color-2);
        }
      }
    }
  }
}
