@import "variables";
@import "bootstrap/scss/mixins";
@import "partials/mixins";


header {
  position: relative;
  z-index: 5;
}

#header::before {
  background-color: var(--theme-accent-color);
  content: " ";
  height: 50px;
  left: 0;
  margin-left: calc(50% - 50vw);
  overflow: hidden;
  position: absolute;
  width: 100vw;
  z-index: 0;
}

.sector-page #header::before {
  height: 100%;
}

/* stylelint-disable-next-line no-duplicate-selectors */
header {
  .masthead {
    transform: translate3d(0, -5px, 0);

    @include media-breakpoint-down(md) {
      left: 50%;
      margin-left: -36px;
      position: absolute;
    }

    img {
      height: 56px;
    }
  }

  .on-page-editor #header > .row > .component::before {
    left: 0;
    transform: unset;
    width: 100%;
  }

  .navbar-brand {
    color: var(--theme-color) !important;
    font-family: Lora, serif;
    font-weight: bold;
  }

  .nav-link--brand {
    color: rgba(0, 0, 0, 54%) !important;
  }

  .nav-link--brand:hover {
    color: rgba(0, 0, 0, 70%);
  }

  .nav-link--main {
    color: rgba(0, 0, 0, 87%);
    font-family: Lora, serif;
    font-size: 1rem;
    font-weight: bold;
  }

  .nav-link--main:first-child {
    padding-left: 0 !important;
  }

  .nav-link--main:last-child {
    padding-right: 0 !important;
  }

  .nav-link--main:hover,
  .nav-link--main:active {
    color: rgba(0, 0, 0, 55%) !important;
    text-decoration: underline;
  }

  .header-logo {
    width: 86px;

    img {
      width: 100%;
    }

    .logo-badge {
      background-color: var(--theme-color);
      border-radius: 2px;
      color: #fff;
      display: block;
      font-family: sans-serif;
      font-size: 0.4375rem;
      font-weight: 700;
      margin-top: 3px;
      padding: 1px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .dropdown-toggle--header::after {
    content: unset !important; /* remove Bootstrap arrow */
  }

  .btn--header-shoppingcart {
    background-color: var(--theme-color);
    border-width: 0;
    color: var(--theme-accent-color);
    font-size: 1rem;
    height: 50px;
    width: 50px;
  }

  .btn--header-shoppingcart:active,
  .btn--header-shoppingcart:focus,
  .btn--header-shoppingcart:hover {
    background-color: var(--theme-accent-color);
    color: var(--theme-color) !important;
  }

  .btn--header-login {
    color: rgba(0, 0, 0, 87%);
  }

  .btn--header-login:hover {
    background-color: var(--theme-accent-color);
    color: var(--theme-color);
  }

  .header__usps {
    color: rgba(0, 0, 0, 87%);
    list-style: none;
  }

  .usp-item {
    font-size: 0.75rem;
    margin: 0;
  }
}
