@import "abstracts/mixins";
@import "abstracts/vars";

.component.toggle-tax {
  .component-content {
    align-content: center;
    display: flex;
    height: 50px;

    label {
      align-items: center;
      color: $gray-500;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-family: $font-family-sans-serif;
      font-size: $font-small;
      line-height: 1;
      margin-bottom: 0;
    }
  }
}
