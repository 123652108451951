@import "variables";
@import "bootstrap/scss/mixins";

$button-height: 50px;

.component.login-button {
  position: unset;

  .component-content {
    text-align: right;

    .btn.btn-link.dropdown-toggle {
      background-color: transparent;
      height: $button-height;
      line-height: 20px;
      z-index: 10;

      &::after { // caret
        display: none !important;
      }

      &:hover,
      &:focus {
        box-shadow: none;
        text-decoration: none;
      }

      &:active,
      &[aria-expanded="true"] {
        box-shadow: $box-shadow-color;
      }

      .user-icon {
        color: var(--theme-color);
        margin-right: -2px;

        svg {
          height: 18px;
        }
      }
    }


    .dropdown-menu {
      border: none;
      box-shadow: $box-shadow-color;
      margin-top: 0;
      width: 100%;

      @include media-breakpoint-up(sm) {
        max-width: 288px;
      }

      &.show {
        z-index: 105;
      }

      .form-group {
        label {
          font-weight: bold;
        }

        .form-check {
          .form-check-input {
            margin-left: 0;
            margin-top: 0;
            position: relative;
            vertical-align: middle;
          }

          label {
            font-weight: normal;
          }
        }

        .form-control {
          background-color: $gray-100;
          border-color: $gray-200 default;
          color: $gray-500;
          font-size: 1rem;
          height: 50px;

          &:focus {
            background-color: $white;
          }
        }
      }

      .btn-primary {
        height: 50px;
        margin-left: 1rem;
      }

      .h3 {
        color: #fff;
      }

      .btn-outline-light {
        border-color: #fff;
        color: #fff !important;

        // fix some alignment issues in Bootstrap and SXA conflicts
        display: inline-grid;
        height: 40px;
        place-items: center;

        &:hover {
          background: none;
        }
      }
    }

    .dropdown-footer {
      background-color: var(--theme-color);
      overflow: hidden;
      position: relative;

      &::before {
        color: rgba(0, 0, 0, 6%);
        content: "\e80d";
        display: block;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: carellurvink-icon;
        font-size: 74px;
        height: 100%;
        margin-right: -34.5px;
        margin-top: -45px;
        position: absolute;
        right: 0;
        top: 50%;
        width: auto;
      }
    }
  }
}
