/* stylelint-disable no-descending-specificity, max-nesting-depth */
@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

@include media-breakpoint-between(xs, lg) {
  .d-sm-only-none {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .component.StoreViewMegaMenu [data-bind-event="click:toggleStoreViewMegaMenu"] {
    background-color: $white;
    transform: translate3d(0, -130px, 0);

    > .fa {
      line-height: unset;
    }
  }
}

@include media-breakpoint-down(lg) {
  .mega-menu {
    width: 720px;
  }

  .promoted-content {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .main-menu {
    flex-direction: column;
  }

  .mega-menu {
    width: 100vw;
  }

  li.toggle-submenu {
    display: none;
  }

  .component.StoreViewMegaMenu {
    .btn-toggle {
      background-color: transparent;
      color: var(--theme-color);
      font-size: 0.875rem;
      font-weight: 500;
      height: 50px;
    }

    nav {
      margin-bottom: 1rem;
    }

    .level1 > a,
    .level2 > a,
    .mega-menu a {
      border-bottom: 1px solid $gray-200;
      display: flex;
      font-family: $font-family-sans-serif !important;
      justify-content: space-between;
      padding: 1rem;
      position: relative;
      width: 100%;

      &.has-submenu::after {
        @include fa-icon;

        content: $fa-var-long-arrow-right;
      }
    }

    .mega-menu {
      > .full-page {
        z-index: 1000;
      }

      > ul {
        display: none;
      }
    }

    .mega-menu:hover,
    .mega-menu:focus-within {
      > ul {
        display: block;
      }

      .lvl-1 {
        ul > {
          display: none;
        }
      }
    }

    .level1.has-submenu {
      > a > .fa-chevron-down {
        border-left: 1px solid $gray-200;
        line-height: inherit;
        padding: 1rem;
        position: absolute;
        right: 0;
        top: 0;

        &::before {
          content: $fa-var-long-arrow-right;
        }
      }
    }
  }
}
