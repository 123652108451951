@import "variables";
@import "bootstrap/scss/mixins";

.product-details-column-splitter {
  @include media-breakpoint-up(lg) {
    width: 100%;

    &.row {
      display: block;
      flex-wrap: initial;
    }

    > .col-12 {
      flex: none;
      float: left;
    }

    > .col-12.order-last {
      float: right;
    }
  }
}
