@import "variables";
@import "abstracts/vars";
@import "abstracts/mixins";
@import "utilities/typography";

.component.teaser {
  width: 100%;

  .field-teaser-title {
    @extend %h1;

    margin-bottom: 0.6rem;
  }

  .field-teaser-image {
    img {
      height: auto;
      width: 100%;
    }
  }

  .field-teaser-subtitle {
    @extend %text-sans-serif;

    font-size: 1.25rem;
    font-weight: 700;
  }
}
