/* stylelint-disable no-descending-specificity, no-duplicate-selectors */
@import "abstracts/vars";
@import "bootstrap/scss/mixins";
@import "utilities/typography";
@import "utilities/theme-colors";
@import "libs/fontawesome/font-awesome-mix-var";

$usp-width: 160px;

footer {
  isolation: isolate;
}

footer #footer {
  .component.image {
    z-index: 10;

    &.position-center {
      text-align: center;
      width: 100%;
    }

    .component-content svg {
      @include media-breakpoint-down(md) {
        height: 114px;
      }
    }
  }

  h3 {
    @extend %h3;

    border: none;
    color: $gray-900;
    font-family: $font-family-serif;
  }

  .link-list.usps {
    .component-content {
      align-items: center;
      display: flex;
      height: 116px;
      margin-bottom: 2rem;
      margin-top: -100px;
      position: relative;
      white-space: nowrap;

      &::before {
        @extend %bg-theme-2;

        content: " ";
        height: inherit;
        left: 0;
        margin-left: -50vw;
        margin-left: calc(50% - 50vw);
        overflow: hidden;
        position: absolute;
        width: 100vw;

        .IE11 & {
          margin-top: -58px;
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        z-index: 10;
      }

      // Override link-list/usps.scss
      li {
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        // Nasty way to make room for 'Shield'
        li.item1 {
          margin-right: $usp-width;
        }
      }

      @include media-breakpoint-down(md) {
        align-items: end;
        height: 143px;
        margin-top: -50px;

        li {
          font-size: 12px;
          width: 50%;

          .field-link {
            align-items: unset;
          }

          a {
            align-items: center;
            display: flex;
            font-size: 12px;
            margin-bottom: 1rem;
            text-align: left;
            white-space: pre-wrap;
            width: auto;
          }

          a::before {
            display: inline-flex;
            font-size: 1rem !important;
            height: 30px;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 5px;
            min-width: 30px;
            transform: none;
            width: 30px;
          }
        }
      }
    }

    .field-link {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > a {
        @extend %text-serif;

        color: #FFF;
        display: block;
        font-weight: 600;
        min-width: 0;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        width: $usp-width;

        &:hover {
          text-decoration: underline;
        }

        &::before {
          @extend %text-theme;

          align-items: center;
          background-color: #FFF;
          border-radius: 100%;
          display: flex;
          font-size: 1.5rem !important;
          height: 60px;
          justify-content: center;
          margin-bottom: 0.75rem;
          margin-left: 50%;
          min-width: 0;
          padding: 0;
          place-items: center;
          transform: translateX(-50%);
          width: 60px;
        }
      }
    }
  }

  .link-list.footer-navigation {
    flex: none;

    .component-content {
      ul {
        margin: 0;
      }

      li a {
        @extend %text-sans-serif;
        @extend %text-color-basic;

        padding: 0;

        &::before {
          content: "";
        }
      }
    }
  }

  .link-list.footer-links-navigation {
    flex: none;

    h3,
    li {
      margin-bottom: 0.5rem;
    }

    ul {
      margin-bottom: 0;
    }

    li {
      color: $gray-500;
      display: inline-block;
      margin-right: 1.5rem;

      a {
        color: inherit;
        padding-left: initial;
      }

      a::before {
        content: "";
      }
    }
  }
}

#footer .active-campaign-newsletter-component {
  h3,
  p {
    width: 100%;
  }

  p {
    color: $gray-500;
    margin-bottom: 0.25rem;
  }

  #_form_13_ {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ._form-content {
      width: 100%;
    }

    .form-control[type="email"],
    .form-control[type="text"] {
      background-color: #F9F9F9;
      border: none;
      font-size: 1rem;
      height: auto;
      padding: 1rem;
    }

    ._error {
      color: #dc3545;
      width: 100%;
    }

    /**
     Base-Themes/Grid-Theme has a selector `input[type=submit]` that adds a lot of properties
     they all need to be overridden in order to make the footer work well in Experience Editor
    */

    .btn-cta-primary {
      @extend %text-sans-serif;

      background-color: $orange;
      background-color: $text-orange;
      background-image: none;
      border-radius: 0.125rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      box-shadow: none;
      color: $text-white;
      cursor: pointer;
      display: inline-block;
      flex-basis: 30%;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: unset;
      margin-bottom: 0;
      margin-left: 0;
      min-width: unset;
      order: 2;
      padding: 1rem;
      text-decoration: none;
      text-shadow: none;
      user-select: none;

      &:hover {
        background-color: $text-orange-active;
        color: $text-white;
      }

      &.disabled {
        background-color: $text-disabled;
        pointer-events: none;
      }
    }
  }
}

#footer .link-list.social-navigation {
  margin-top: 1rem;

  .field-link {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

#footer .container-fluid:last-child {
  margin-top: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;

  &::before {
    border-bottom: 1px solid $gray-100;
    content: " ";
    left: 0;
    margin-left: calc(50% - 50vw);
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 0;
  }

  .row {
    align-items: flex-end;
  }
}


@include media-breakpoint-down(sm) {
  .component.footer-navigation {
    [data-toggle="collapse"] {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
    }

    [data-toggle="collapse"]::after {
      content: $fa-var-angle-up;
      font-size: 1.25rem !important;
      @include fa-icon;
    }


    h3[data-toggle="collapse"].collapsed::after {
      content: $fa-var-angle-down;
    }

    ul {
      border-bottom: 1px solid $gray-200 !important;

      &:not(.show) {
        display: block;
        height: 1px;
        overflow: hidden;
      }
    }

    ul li {
      padding-bottom: 0.5rem;

      &:last-child {
        padding-bottom: 1rem;
      }
    }
  }
}
