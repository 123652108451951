/* stylelint-disable no-descending-specificity */
@import "variables";

.navigation-fat .component-content,
.sidebar-navigation.navigation-fat .component-content {
  border: 1px solid #fff;
  box-shadow: $box-shadow-color;
  position: relative;

  h3 {
    color: var(--text-basic);
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-weight: 900;
    padding-left: 1rem;
  }

  li {
    border-bottom: 1px solid $gray-200;
    height: 50px;
    padding: 0;
  }

  li:last-child {
    border-bottom: none;
  }

  a {
    color: $gray-900;
    display: block;
    font-size: 1rem;
    height: 100%;
    overflow: hidden;
    padding: 0.75rem 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:hover {
      color: var(--theme-color-2);
    }

    &.active {
      color: var(--theme-color-2);
    }
  }
}
