@import "variables";

[data-component="featured-subcategories"] {
  .col {}

  a {
    border: 1px solid $gray-200;
    border-radius: $border-radius;

    &:hover {
      border-color: var(--theme-color-2);
      text-decoration: none;
    }
  }

  img {
    height: 100%;
    max-height: 50px;
    width: auto;
  }
}
