@import "abstracts/vars";
@import "utilities/theme-colors";
@import "libs/fontawesome/font-awesome-mix-var";

.component.product-details-usp {
  .plus-circle {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;

    &::before {
      @include fa-icon;
      @extend %text-theme;

      content: $fa-var-plus-circle;
      font-size: 18px;
      margin-right: 0.75rem;
    }
  }
}
