/* stylelint-disable no-duplicate-selectors, scss/no-global-function-names */
@use "sass:color";
@import "partials/mixins";
@import "abstracts/vars";


.component.blog-articles-list {
  /* arranging */
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;

  .article-card {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    max-width: 100%;
    min-height: 1px;
    width: 100%;

    .article-image,
    .article-body {
      flex: 0 0 50%;
      flex-wrap: wrap;
      max-width: 50%;
      width: 50%;
    }

    ~ .article-card {
      flex: 0 0 50%;
      max-width: 50%;

      .article-image,
      .article-body {
        flex: 0 0 100%;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  /* top article and defaults */
  .article-image {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;

    img {
      display: block;
      width: 100%;
    }
  }

  .article-body {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    color: $white;
    padding-top: 5rem !important;
    position: relative;

    &::before {
      color: rgba(255, 255, 255, 12%);
      content: "\e80d";
      display: block;
      font-family: carellurvink-icon;
      font-size: 210px;
      height: 100%;
      margin-left: -98px;
      margin-top: -105px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 98px;
    }

    &.article-Facility {
      background: linear-gradient(67.32deg, darken($green, 10) 0%, $green 100%);
      background-color: $green;
    }

    &.article-Industry {
      background: linear-gradient(67.32deg, darken($yellow, 10) 0%, $yellow 100%);
      background-color: $yellow;
    }

    &.article-Food {
      background: linear-gradient(67.32deg, darken($blue, 10) 0%, $blue 100%);
      background-color: $blue;
    }

    &.article-Medical {
      background: linear-gradient(67.32deg, darken($cyan, 10) 0%, $cyan 100%);
      background-color: $cyan;
    }
  }

  .article-autor,
  .article-date {
    color: $white;
  }

  .article-autor {
    font-weight: 600;
  }

  .title {
    color: $white;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
  }

  .badge {
    margin-bottom: 1rem;
  }

  // TODO: rework with data-attributes
  .badge-facility {
    background-color: $white;
    color: $green !important;
  }

  .badge-industry {
    background-color: $white;
    color: $yellow !important;
  }

  .badge-food {
    background-color: $white;
    color: $blue !important;
  }

  .badge-medical {
    background-color: $white;
    color: $cyan !important;
  }

  /* article list */
  .article-card ~ .article-card {
    .article-image {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: $border-radius !important;
    }

    .article-body {
      background: none;
      background-color: #ffffff;
      border-bottom-left-radius: $border-radius !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      color: $gray-900;
      padding: 1rem !important;

      .title {
        font-size: 1rem;
      }
    }

    .article-autor,
    .article-date {
      display: none;
    }

    .title {
      color: $gray-900;
    }

    .badge {
      margin-bottom: 0.25rem;
    }

    .badge-Facility {
      background-color: $green;
      color: $white !important;
    }

    .badge-Industry {
      background-color: $yellow;
      color: $white !important;
    }

    .badge-Food {
      background-color: $blue;
      color: $white !important;
    }

    .badge-Medical {
      background-color: $cyan;
      color: $white !important;
    }
  }
}
