@import "variables";

%h1 {
  font-family: $font-family-serif;
  font-size: $h1-font-size;
  font-weight: 700;
  line-height: 1.33;
}

%h2 {
  font-family: $font-family-sans-serif;
  font-size: $h2-font-size;
  font-weight: 700;
  line-height: 1.4;
}

%h3 {
  font-family: $font-family-sans-serif;
  font-size: $h3-font-size;
  font-weight: 700;
  line-height: 1.5;
}


%text-sans-serif {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: 1.42;
}

%text-serif {
  font-family: $font-family-serif;
  font-size: $font-size-base;
  line-height: 1.42;
}

%text-color-basic {
  color: $gray-500;
}
