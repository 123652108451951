/* stylelint-disable no-descending-specificity, max-nesting-depth */
@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";

@include media-breakpoint-down(sm) {
  .component.LoggedInNavigation {
    &.is-open .full-page {
      transform: translateY(0);
    }


    .full-page {
      background-color: $white;
      display: flex;
      flex-direction: column;
      height: 100vh;
      left: 0;
      overflow: hidden;
      position: fixed;
      top: 0;
      transform: translateY(120%);
      transition: transform 0.3s ease-in-out;
      width: 100vw;
      z-index: 1000;
    }


    .full-page__scrollpane {
      height: 100%;
      overflow-x: hidden;
      width: 100%;
    }

    .full-page__scrollpane-content {
      height: 100%;
      position: relative;
      width: 100%;

      .filter {
        width: 100%;
      }
    }

    .full-page__header {
      align-items: center;
      background-color: $white;
      border-bottom: 1px solid $gray-200;
      display: flex;
      height: 50px;
      justify-content: space-between;
      position: relative;
      width: 100%;

      .h1 {
        font-size: 1rem;
      }
    }

    .full-page__footer {
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 20%) 0 0 8px 0;
      height: 80px;
      width: 100%;
      z-index: 1;
    }

    .btn-toggle {
      color: var(--theme-color);
      font-size: 0.875rem;
      font-weight: 500;
      height: 50px;
    }
  }
}


