/* stylelint-disable no-descending-specificity, no-duplicate-selectors */

@import "abstracts/vars";
@import "abstracts/mixins";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";

.noScroll {
  @include media-breakpoint-up(lg) {
    padding-right: 17px;
  }

  overflow: hidden !important;
}


.component.sector-navigation {
  .toggleButton {
    align-items: center;
    background: transparent !important;
    border: none;
    color: var(--theme-color) !important;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-family: Lora, serif;
    font-size: 0.875rem;
    font-weight: bold;
    height: 50px; // Header height
    line-height: inherit;
    margin: 0 1rem 0 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto !important;

    &::before {
      @include fa-icon;

      content: $fa-var-chevron-down;
      font-size: 0.875rem;
      line-height: 0.5;
      margin-right: 0.875rem;
    }

    &.active::before {
      content: $fa-var-chevron-up;
    }
  }

  .sector-dropdown {
    background-color: $bg-basic-color;
    content-visibility: hidden;
    height: calc(100vh - 50px);
    left: 0;
    opacity: 100%;
    padding: 1rem;
    position: fixed;
    top: 50px;
    transition: opacity 250ms linear;
    width: 100vw;
    z-index: 1000;

    &:hover .sector-image {
      filter: brightness(88%);
    }

    &.shown {
      contain: style layout paint;
      content-visibility: visible;
    }

    &.hidden {
      border: 0;
      clip: rect(0 0 0 0);
      content-visibility: hidden;
      height: 1px;
      margin: -1px;
      opacity: 0%;
      overflow: hidden;
      padding: 0;
      position: fixed;
      width: 1px;
    }

    > .sectors {
      display: grid;
      grid-gap: 1rem;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;

        .sector-image {
          height: 20vh;
          overflow: hidden;
        }
      }
    }
  }

  .sector-image {
    align-items: center;
    background:
      linear-gradient(
        135deg,
        var(--theme-color-2) 0%,
        var(--theme-color-2) 30%,
        var(--theme-color-4) 30%,
        var(--theme-color-4) 100%
      );
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }

  .sector-image:hover {
    filter: none !important;
  }

  .sector-image img {
    height: 100%;
    max-height: calc(50vh - 50px);
    width: auto;
  }

  .sector-image:nth-child(3):nth-last-child(1) {
    grid-column: span 2;
  }

  .sector-image:nth-child(2):nth-last-child(3) {
    grid-column: 2;
  }

  .sector-link {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    width: calc(100% - 4rem);
  }

  .sector-button {
    background-color: var(--theme-color-2);
    border-radius: 2px;
    color: $white;
    font-family: $font-family-serif;
    font-size: 1.25rem;
    font-weight: 600;
    height: 56px;
    line-height: 56px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    right: 2rem;
    white-space: nowrap;
  }

  .sector-image:hover .sector-button::after {
    @include fa-icon;

    content: $fa-var-long-arrow-right;
    line-height: 56px;
    margin-left: 1rem;
  }
}
