@import "abstracts/vars";
@import "utilities/theme-colors";
@import "libs/fontawesome/font-awesome-mix-var";

.component.iconed-list {
  li:last-child {
    margin-bottom: 0;
  }

  .check-circle {
    align-items: center;
    display: flex;
    font-size: 1rem;
    height: 20px;
    margin-bottom: 1rem;

    &::before {
      background-image: url("../../../static-assets/images/checkmark-circle.svg");
      background-position: center;
      content: " ";
      display: inline-block;
      height: 20px;
      margin-right: 1rem;
      width: 20px;
    }
  }

  .plus-circle {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;

    &::before {
      @include fa-icon;
      @extend %text-theme;

      content: $fa-var-plus-circle;
      font-size: 18px;
      margin-right: 0.75rem;
    }
  }

  &.bordered {
    ul {
      border: 1px solid rgba(0, 0, 0, 21%);
      border-radius: 2px;
      padding: 1rem;
    }
  }
}
