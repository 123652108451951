/* stylelint-disable max-nesting-depth */
@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";


// $menuItemWidth: 224px;
$menuItemWidth: 324px;

.component.StoreViewMegaMenu .submenu .level-0 {
  @extend %text-sans-serif;
}

/* Desktop */
@include media-breakpoint-up(lg) {
  .component.StoreViewMegaMenu {
    width: 100%;

    .phone-number {
      align-items: center;
      display: inline-flex;
      position: relative;

      svg {
        color: var(--theme-color-1);
        height: 16px;
        left: -2.75rem;
        position: absolute;
      }
    }

    ul {
      list-style: none;
    }

    .nav-overlay {
      background-color: rgba(255, 255, 255, 1%);
      bottom: 0;
      display: none;
      left: 0;
      opacity: 0%;
      overflow: hidden;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity 500ms;
      z-index: 1;
    }

    .nav-overlay.active {
      display: block;
      opacity: 1%;
    }

    nav {
      position: relative;

      > ul {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;

        > li {
          border-bottom: 3px solid transparent;
          display: inline-block;
          margin-bottom: 0;
          margin-right: 2rem;
          padding: 0 0 1rem;
          position: relative;
          z-index: 100;

          &:last-child {
            margin-right: 0;
          }

          &:last-child:hover {
            border-color: transparent;
          }

          &.active,
          &:hover,
          &:focus-within {
            border-bottom: 3px solid var(--theme-color);

            > a {
              color: $gray-600;
              text-decoration: none;
            }
          }

          a {
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }

      .level1.has-submenu {
        &.active,
        &:hover,
        &:focus {
          > a > .fa-chevron-down::before {
            content: $fa-var-chevron-up;
          }

          > .full-page {
            display: block;
          }
        }

        > .full-page {
          background: #fff;
          border-radius: 0 0 10px 10px;
          box-shadow: 20px 25px 30px 0 rgba(0, 0, 0, 10%);
          display: none;
          margin-top: 3px;
          min-width: 100%;
          position: absolute;
          top: 100%;
        }

        > .full-page li {
          border-top: 1px solid rgba(0, 0, 0, 12%);

          &:first-child {
            border-top: none;
          }
        }

        > .full-page li > a {
          display: block;
          font-family: $font-family-sans-serif;
          font-size: 14px !important;
          padding: 1rem;
          white-space: nowrap;

          &.active,
          &:hover,
          &:focus {
            color: var(--theme-color-2);
            text-decoration: none;
          }
        }
      }
    }

    .mega-menu-container {
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      box-shadow: 20px 12px 34px 0 rgba(0, 0, 0, 10%);
      display: none;
      min-height: 390px;
      position: absolute;
      top: 42px;
      width: 1210px;


      &::after {
        background-color: #e0e0e0;
        content: "";
        height: 100%;
        left: $menuItemWidth - 1;
        min-height: 390px;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: 0;
      }

      &.is-open {
        display: block;
      }
    }

    .mega-menu {
      border-radius: 0 0 0 10px;
      display: none;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      width: $menuItemWidth;

      &.is-open {
        display: flex;
      }
    }

    .menu-item-pane {
      align-content: stretch;
      background: $white;
      border-bottom-right-radius: 10px;
      display: none;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      left: $menuItemWidth;
      position: absolute;
      top: 0;
      width: calc(100% - $menuItemWidth);
    }

    .mega-menu-item {
      background-color: #f9f9f9;
      border-bottom: 1px solid #e0e0e0;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: $menuItemWidth;
      z-index: 100;

      a {
        display: block;
        font-size: 0.875rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;

        &:hover {
          color: var(--theme-color-2);
        }
      }

      &:last-child {
        border-radius: 0 0 0 10px;
      }
    }

    .mega-menu-item.is-open,
    .mega-menu-item:hover {
      background-color: $white;
      border-right-color: transparent;

      &:last-child {
        border-bottom: 1px solid $gray-200;
      }

      & > a {
        color: var(--theme-color-2);
      }

      .menu-item-pane {
        display: block;
      }

      .level-1 {
        a {
          color: $gray-900;
          display: block;
          font-family: Roboto, sans-serif;
          font-size: 0.875rem;
          max-width: 290px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          &.has-submenu::before {
            @include fa-icon;

            content: $fa-var-long-arrow-right;
            display: inline-block;
            float: right;
            line-height: inherit;
          }

          &.active,
          &:hover,
          &:focus {
            color: var(--theme-color-2);
            text-decoration: none;
          }
        }

        &.with-subcategories a::before {
          content: unset !important;
        }
      }

      .fa {
        line-height: 2;
      }
    }

    .submenu.lvl-1 {
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      grid-template-rows: repeat(9, min-content);
      height: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1rem;
      width: 100%;
    }

    .submenu.lvl-1.with-subcategories {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap: unset;
      height: auto;
      padding-bottom: 1rem;
    }



    .submenu.lvl-1 .submenu {
      display: none;
    }

    .submenu.lvl-1 .has-submenu.is-open + .full-page > .full-page__scrollpane-content > .submenu {
      background: $white;
      display: grid;
      grid-gap: 0;
      grid-template-rows: repeat(auto-fill, 2rem);
      left: 0;
      min-height: 100%;
      padding-bottom: 1rem;
      padding-left: 2rem;
      padding-top: 3rem;
      position: absolute;
      top: 0;
      width: 100%;

      &.has-promoted-content {
        width: calc(100% - 284px);
      }
    }

    .submenu.lvl-1 .has-submenu.is-open + .full-page > .full-page__scrollpane-content > .submenu .submenu {
      border-radius: 0 0 10px;
      height: 100%;
      width: 100%;
    }

    .submenu.lvl-2 {
      border-radius: 0 0 10px;
    }

    .submenu.with-subcategories li.with-subcategories {
      margin-bottom: 1rem;
    }

    .submenu.with-subcategories li {
      width: 33%;
    }


    .submenu.with-subcategories li li {
      margin-left: unset;
      padding-left: unset;
      padding-right: unset;
      width: 100%;
    }

    .submenu li {
      line-height: 2rem;
    }

    .submenu li li {
      background-color: #fff;
      margin-left: -32px;
      padding-left: 32px;
      padding-right: 32px;

      &:last-child {
        border-radius: 0 0 10px;
      }
    }

    .submenu li > a {
      font-weight: 400;
    }

    .submenu.lvl-1 > li > a {
      font-weight: 600;
    }

    .submenu .toggle-submenu {
      margin-left: 2rem;
      margin-top: 1rem;
      padding-left: 0;
      position: absolute;
    }

    .submenu .toggle-submenu-btn {
      color: $gray-900;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 700;

      &::before {
        @include fa-icon;

        color: var(--theme-color-2);
        content: $fa-var-long-arrow-left;
        margin-right: 0.75rem;
      }
    }

    .promoted-content {
      background-color: var(--theme-color-4);
      border-bottom-right-radius: 10px;
      height: 100%;
      overflow: hidden;
      padding: 2rem;
      width: 284px;
    }

    .promoted-content h3 {
      font-size: 0.875rem;
    }

    .promoted-content a {
      color: $gray-500;
      font-family: Roboto, sans-serif;
      font-size: inherit;
      font-weight: 400;
      padding: 0;
    }

    .featured-categories {
      li {
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }

    .featured-brands {
      display: flex;
      flex-wrap: wrap;

      > div {
        flex: 1 0 auto;
        margin-top: 0.5rem;
      }

      img {
        background-color: #fff;
        border: none;
        filter: opacity(0.9) grayscale(100%) drop-shadow(0 8px 8px rgba(66, 66, 66, 15%));
        height: 50px;
        max-width: unset;
        padding: 0.875rem 1.125rem;
        transition: filter 0.25s ease-in-out;
        width: auto;

        &:hover {
          filter: opacity(1) grayscale(0) drop-shadow(0 8px 8px rgba(66, 66, 66, 45%));
        }
      }
    }
  }
}
