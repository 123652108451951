@import "variables";
@import "bootstrap/scss/mixins";
@import "utilities/typography";

.component.facet-sector .component-content,
.component.facet-blogcategory .component-content {
  margin-bottom: 1rem;

  label {
    margin-bottom: 0;
  }

  .facet-search-filter {
    columns: 200px auto;

    @include media-breakpoint-up(md) {
      columns: 130px auto;
    }
  }

  .facet-heading {
    @extend %h3;
  }

  .facet-title {
    margin-bottom: 0.25rem;
  }

  .clear-filter {
    display: none;
  }

  .facet-value {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;

    label {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    .facet-label {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .facet-count {
      font-size: 0.875rem;
      margin-left: 0.25rem;
    }


    .bottom-remove-filter {
      display: none;
    }

    .bottom-filter-button {
      input[type="button"] {

      }
    }
  }
}
