/* stylelint-disable no-duplicate-selectors, no-descending-specificity */
@import "abstracts/vars";
@import "abstracts/mixins";
@import "utilities/typography";

.component.product-media-carousel {
  max-width: 738px;

  @import "./labels";


  .glider-contain {
    min-height: 420px;

    >.product-image ~ .product-image {
      display: none;
    }
  }

  .product-image {
    height: 420px;
    max-width: 783px;
    position: relative;
    text-align: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .fullscreen-button {
    bottom: 2rem;
    position: absolute;
    right: 0;
  }

  .product-thumbnails {
    background-color: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 110px;
    justify-content: space-between;
    width: 100%;
  }


  .slide-button {
    align-items: center;
    display: flex;
    flex: 0 0 90px;
    justify-content: center;
    width: 90px;

    .btn {
      align-items: center;
      background-color: transparent;
      border: 2px solid $text-orange;
      color: $text-orange;
      display: flex !important;
      font-size: 2rem;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      padding: 0;
      text-decoration: none;
      width: 40px;

      &.disabled {
        opacity: 25%;
        pointer-events: none;
      }
    }
  }

  .thumbnail-glider {
    align-items: center;
    width: 556px;
  }

  .slide-item {
    display: grid;
    place-content: center;
    position: relative;
  }

  .slide-item-thumbnail {
    border: 1px solid transparent;
    height: 76px;
    width: 76px;
  }

  /* Some thumbnails are not square, pseudo-elements create a square white background */
  .slide-item::before {
    background-color: $white;
    border: 2px solid transparent;
    content: " ";
    height: 80px;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    position: absolute;
    top: 50%;
    width: 80px;
    z-index: -1;
  }

  .slide-item:has(.active)::before {
    border-color: $gray-300;
  }

  .slide-item > img {
    height: 100%;
    max-height: 78px;
    max-width: 78px;
    width: auto;
    z-index: 1;
  }

  .media-control {
    background-color: $white;
    display: grid;
    place-items: center;
  }

  .glider.draggable {
    scrollbar-width: none;
  }

  .glider-track {
    scrollbar-width: none;
  }

  .glider-dot {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 100%;
    cursor: pointer;
    display: inline-flex;
    height: 12px;
    margin: 0;
    margin-right: 0.5rem;
    width: 12px;

    &:hover {
      background-color: var(--theme-color-2);
      border-width: 1px;
    }

    &.active {
      background-color: var(--theme-color-2);
      border-color: transparent;
    }
  }
}

.pinch-zoom-container {
  background-color: $white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}
