/* stylelint-disable no-duplicate-selectors */
@use "sass:color";
@import "partials/mixins";
@import "abstracts/vars";

/* Reset SXA defaults */
.component.related-pages.search-results {
  li {
    display: inline-block;
    margin: unset;
    padding: unset;
    position: static;
    vertical-align: unset;
    width: unset;
  }
}

.component.related-pages.search-results {
  a {
    color: iniherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;

      .field-teaser-title {
        @include supports ("--css: variables") {
          color: var(--theme-color-1);
        }
      }
    }
  }

  li {
    border-bottom: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
    padding-bottom: 1rem;
    padding-top: 1rem;

    &:hover {
      border-bottom: 1px solid $gray-200 !important;
    }
  }

  li ~ li {
    border-top: none;
  }

  .badge {
    display: inline-block !important;
    margin-bottom: 0.5rem;
    margin-top: 0 !important;
  }

  .field-teaser-title {
    color: $gray-900;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }
}
