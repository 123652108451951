/* stylelint-disable no-descending-specificity, max-nesting-depth */
@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
  .component.StoreViewMegaMenu {
    .is-open + .component-content > .full-page {
      display: block;
    }

    .is-open + .full-page {
      display: block;
    }

    .full-page {
      background-color: $white;
      display: flex;
      display: none;
      flex-direction: column;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
    }


    .full-page__scrollpane {
      height: 100%;
      overflow-y: auto;
      width: 100%;
    }

    .full-page__scrollpane-content {
      height: 100%;
      overflow-y: auto;
      position: relative;
      width: 100%;

      .filter {
        width: 100%;
      }
    }

    .full-page__header {
      align-items: center;
      background-color: $white;
      border-bottom: 1px solid $gray-200;
      display: flex;
      height: 50px;
      justify-content: space-between;
      position: relative;
      width: 100%;

      .h1 {
        font-size: 1rem;
      }

      .btn-toggle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .full-page__footer {
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 20%) 0 0 8px 0;
      height: 80px;
      width: 100%;
      z-index: 1;
    }
  }
}


