@import "variables";
@import "bootstrap/scss/mixins";

.product-symbols {
  * {
    @include media-breakpoint-down(sm) {
      &:nth-child(n+10) {
        display: none;
      }
    }
  }

  img {
    margin-right: 0.5rem;
    max-height: 67px;
    max-width: 67px;
  }

  .modal-header,
  .modal-footer {
    border-color: $gray-200;
  }

  .btn-modal-close {
    font-size: 0.875rem;
    font-weight: 600;
    height: 50px;
  }
}
