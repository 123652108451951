@import "variables";

.product-sample-request {
  a {
    .fa {
      margin-right: 0.25rem;
    }

    :hover .fa {
      text-decoration: none;
    }
  }

  .modal {
    .form-control {
      border-color: $gray-200;
      height: 50px;
    }

    ._has_error {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
      background-position: right calc(0.3125em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.625em + 0.375rem) calc(0.625em + 0.375rem);
      border-color: $danger;
      padding-right: calc(1.25em + 0.75rem) !important;
    }

    ._error {
      color: $danger;
      font-size: 80%;
      margin-top: 0.25rem;
      width: 100%;
    }

    .btn {
      color: $white;
      height: 50px;
    }
  }
}
